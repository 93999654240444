import epoqConfig from "./epoq-config";
import useDepartment from "@/SearchApp/composables/use-department";

const { departmentCapitalized } = useDepartment();

export const getEpoqRequestParams = (query = "") => {
	const { customerId, tenantId, sessionId } = epoqConfig();

	return {
		customerId,
		format: "json",
		// ⚠️ attribute name has to be "e:gender" but value has to be department
		matchAttr: "e:gender",
		matchType: "filter",
		matchValue: departmentCapitalized.value,
		query,
		sessionId,
		style: "compact",
		tenantId,
	};
};
