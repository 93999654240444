import pushDataLayer from "@ocm/services/dataLayer";

const TRACKING_PREFIX = window.ocm?.isClassicSite ? "C_" : "S_";
export const dataLayerPush = (action = "") => {
	pushDataLayer({
		event: "GAevent",
		event_category: `${TRACKING_PREFIX}1stlevel`,
		event_action: `${TRACKING_PREFIX}${action}`,
	});
};
