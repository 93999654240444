import { language } from "@ocm/services/ocm-object";
import { messengerPublicationTypes } from "@ocm/services/services.constants";
import OcmMessenger from "ocm_mercurius_messenger";

type BannerI18n = {
	headline?: string;
	message?: string;
};

const messageI18n = new Map([
	["de", { newPasswordHeadline: "Neues Passwort", newPasswordMessage: "erfolgreich vergeben" }],
	["en", { newPasswordHeadline: "New Password", newPasswordMessage: "has been set" }],
]);

const KC_ACTION_STATUS = "kc_action_status";

export function handleSuccessfulKeycloakProcess() {
	const queryString = window.location.search;
	const urlParams = new URLSearchParams(queryString);
	const usedLanguage = language === "de" ? "de" : "en";

	const actions = [
		{
			actionStatusValue: "success_password_forgotten",
			eventAction: "PasswordForgotten",
			banner: {
				headline: messageI18n.get(usedLanguage)?.newPasswordHeadline,
				message: messageI18n.get(usedLanguage)?.newPasswordMessage,
			},
		},
	];

	actions.forEach(({ actionStatusValue, banner }) => {
		if (urlParams.get(KC_ACTION_STATUS) === actionStatusValue) {
			if (banner) showBanner(banner);
			removeQueryParameterFromUrl(urlParams);
		}
	});
}

function showBanner(banner: BannerI18n) {
	OcmMessenger.publish(messengerPublicationTypes.ADD_BANNER, {
		type: "success",
		headline: banner.headline,
		message: banner.message,
		hideBannerFocus: true,
	});
}

function removeQueryParameterFromUrl(urlParams: URLSearchParams) {
	const updatedQuery = new URLSearchParams(urlParams);
	updatedQuery.delete(KC_ACTION_STATUS);
	const newUrl = updatedQuery.toString()
		? `${window.location.pathname}?${updatedQuery.toString()}`
		: window.location.pathname;
	history.replaceState(null, "", newUrl);
}
